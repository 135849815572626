@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #F9F9F9 !important;
}

/* .heading-one,
.markdown h1 {
  @apply text-[22px] font-medium font-mulish leading-7 text-primary-white;
}

.text-large,
.markdown p {
  @apply text-base font-semibold font-mulish leading-7 text-primary-lightWhite;
}

.text-small,
.markdown p {
  @apply text-xs font-medium font-mulish leading-[14px] text-secondary-grey;
}

.button,
.markdown button {
  @apply font-poppins text-lg leading-6 text-center text-primary-white;
}

.text-md,
.markdown p {
  @apply font-mulish font-medium text-xs leading-4 text-primary-red;
}

.text-xsmall,
.markdown p,
span {
  @apply font-mulish font-normal text-sm leading-5;
}

.modal {
  overflow: hidden;
}

.Toastify__toast-container {
  padding: 10px !important;
}

.customradio {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  outline: none;
  border: 2px solid #FF3A3A;
}

.customradio:checked::before {
  content: "";
  display: block;
  width: 70%;
  height: 70%;
  margin: 17% 17%;
  border-radius: 50%;
  background-color: #FF3A3A;
} */
